// UserContext.tsx
"use client";

import React, { createContext, useContext, useState } from "react";
import { SafeUserWithOffice } from "./getCurrentUser";
import { RoleInherited } from "./authorization";
import axios from "axios";

interface UserContextValue {
  userData?: SafeUserWithOffice;
  isLoading: boolean;
  isAuthenticated: boolean;
  updateRoleInherited: (roleInherited: RoleInherited | null) => void;
  refreshUser?: () => void;
  updateUser: (userData: Partial<SafeUserWithOffice>) => void;
  // Optionally: functions to log out, refresh user data, etc.
}

const UserContext = createContext<UserContextValue>({
  userData: undefined,
  isLoading: true,
  isAuthenticated: false,
  updateRoleInherited: () => {},
  updateUser: () => {},
});

interface UserProviderProps {
  children: React.ReactNode;
  initialUser?: SafeUserWithOffice;
}

export const CurrentUserProvider: React.FC<UserProviderProps> = ({ children, initialUser }) => {
  const usersLocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; //IANA Timezone

  const [userData, setUserData] = useState<SafeUserWithOffice | undefined>({ ...initialUser, timeZone: usersLocalTimeZone } as SafeUserWithOffice);
  const isLoading = false; // If you fetch on mount, you might use a state or effect for this
  const isAuthenticated = !!userData;

  const updateRoleInherited = (roleInherited: RoleInherited | null) => {
    setUserData((prevUserData) => {
      if (prevUserData) {
        return {
          ...prevUserData,
          roleInherited,
          timeZone: usersLocalTimeZone,
        };
      }
      return { ...initialUser, timeZone: usersLocalTimeZone } as SafeUserWithOffice;
    });
  };

  const updateUser = (newUserData: Partial<SafeUserWithOffice>) => {
    setUserData((prevUserData) => {
      if (prevUserData) {
        return { ...prevUserData, ...newUserData, timeZone: usersLocalTimeZone };
      }
      return { ...newUserData, timeZone: usersLocalTimeZone } as SafeUserWithOffice;
    });
  };

  const refreshUser = async () => {
    try {
      const currentUser = await axios.get("/api/getCurrentUser");
      setUserData((prevUserData) => {
        if (prevUserData) {
          return { ...prevUserData, ...currentUser.data.user, timeZone: usersLocalTimeZone };
        }
        return { ...currentUser.data.user, timeZone: usersLocalTimeZone };
      });
    } catch (error) {
      console.error("Error refreshing user:", error);
    }
  };

  return (
    <UserContext.Provider value={{ userData, isLoading, isAuthenticated, updateRoleInherited, updateUser, refreshUser }}>{children}</UserContext.Provider>
  );
};

// A helper hook to easily consume the UserContext
export function useCurrentUser() {
  return useContext(UserContext);
}
