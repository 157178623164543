import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { NextRouter } from "next/router";

export const RefreshWindow = (ifTrue?: boolean) => {
  if (ifTrue === undefined || ifTrue) {
    window.location.reload();
  }
};

export const fullPageReload = (url?: string) => {
  window.location.replace(url || window.location.href);
};

export const navigateToPath = (router?: NextRouter | AppRouterInstance, path?: string, e?: any) => {
  //If the URL points to a page outside your Next.js app or if you deliberately want a full page reload, you can use window.location.href = url or window.location.assign(url).
  if (!path) {
    RefreshWindow();
    return;
  }

  // If Cmd (Mac) or Ctrl (Windows/Linux) key is pressed, open in a new tab
  if (e?.metaKey || e?.ctrlKey) {
    window.open(path, "_blank");
    return;
  }

  // Get the current path and query parameters
  const currentPathSegments = window.location.pathname.split("/").filter((segment) => segment);
  const currentQueryParams = new URLSearchParams(window.location.search);

  const currentQueryParamsObject: Record<string, any> = {};
  currentQueryParams.forEach((value, key) => {
    currentQueryParamsObject[key] = value;
  });

  // Split the target path into segments and extract query parameters
  const [pathWithoutQuery, queryString] = path.split("?");
  const targetPathSegments = pathWithoutQuery.split("/").filter((segment) => segment);
  const targetQueryParams = new URLSearchParams(queryString || "");

  const targetQueryParamsObject: Record<string, any> = {};
  targetQueryParams.forEach((value, key) => {
    targetQueryParamsObject[key] = value;
  });

  // Compare path segments
  const isSamePath =
    currentPathSegments.length === targetPathSegments.length && currentPathSegments.every((segment, index) => segment === targetPathSegments[index]);

  // Compare query parameters
  const isSameQueryParams = JSON.stringify(currentQueryParamsObject) === JSON.stringify(targetQueryParamsObject);

  // If both path and query parameters are the same
  const isCurrentPathAndQuery = isSamePath && isSameQueryParams;

  if (isCurrentPathAndQuery) {
    RefreshWindow();
    return;
  } else {
    //navigate to the new path
    if (router) router.push(path);
    //If the URL points to a page outside your Next.js app or if you deliberately want a full page reload, you can use window
    else window.location.href = path;
  }
};

export const setTextSelected = (element: HTMLElement | string | undefined, start?: number, end?: number) => {
  if (!element) return;

  if (typeof element === "string") {
    element = document.getElementById(element) as HTMLElement;
  }

  if (!element) {
    console.error("Element not found");
    return;
  }

  const textNode = element.firstChild ?? element;
  if (!textNode || textNode.nodeType !== Node.TEXT_NODE) {
    console.error("Element does not contain a text node");
    return;
  }

  if (start === undefined || end === undefined) {
    console.error("Start or end is undefined");
    return;
  }

  const range = document.createRange();
  range.setStart(textNode, start);
  range.setEnd(textNode, end);
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
};
